




































interface UnitDetails {
  text: string
  max: number
}

type UnitKey = 1 | 1000 | 60000 | 3600000 | 86400000 | 2592000000 | 31536000000

type Units = Map<UnitKey, UnitDetails>

export default defineComponent({
  props: {
    end: Number,
    limit: Number,
    start: Number,
  },
  setup(props, { emit }) {
    const dialog = ref(false)

    const units: Units = new Map([
      [
        1,
        {
          max: 1999,
          text: "milliseconds",
        },
      ],
      [
        1000,
        {
          max: 119,
          text: "seconds",
        },
      ],
      [
        60000,
        {
          max: 119,
          text: "minutes",
        },
      ],
      [
        3600000,
        {
          max: 47,
          text: "hours",
        },
      ],
      [
        86400000,
        {
          max: 59,
          text: "days",
        },
      ],
      [
        2592000000,
        {
          max: 23,
          text: "months",
        },
      ],
      [
        31536000000,
        {
          max: 30,
          text: "years",
        },
      ],
    ])

    function getUnit(value: number) {
      let previous: UnitKey = 1
      for (const key of units.keys()) {
        if (Math.abs(value) < key * 2) {
          return previous
        }
        previous = key
      }
      return previous
    }

    const unitSelect = [
      {
        text: "Milliseconds",
        value: 1,
      },
      {
        text: "Seconds",
        value: 1000,
      },
      {
        text: "Minutes",
        value: 60000,
      },
      {
        text: "Hours",
        value: 3600000,
      },
      {
        text: "Days",
        value: 86400000,
      },
      {
        text: "Months",
        value: 2592000000,
      },
      {
        text: "Years",
        value: 31536000000,
      },
    ]

    const unit = ref(1000 as UnitKey)
    const unitDetails = computed(() => units.get(unit.value) ?? { max: 0, text: "unknown" })

    const limitValue = ref(100)
    const value = ref([0, 0])

    function init() {
      const start = props.start ?? -3600000
      const end = props.end ?? 0
      const UNIT = getUnit(Math.max(Math.abs(start), end))
      unit.value = UNIT
      limitValue.value = props.limit ?? 100
      value.value = [start / UNIT, end / UNIT]
    }

    watchEffect(init)

    function sendUpdate() {
      emit("input", {
        end: value.value[1] * unit.value,
        limit: limitValue.value,
        start: value.value[0] * unit.value,
      })

      dialog.value = false
    }

    function beforeOrAfter(value: number) {
      return value < 0 ? "before" : "after"
    }

    return {
      beforeOrAfter,
      dialog,
      init,
      limitValue,
      sendUpdate,
      unit,
      unitDetails,
      unitSelect,
      value,
    }
  },
})
