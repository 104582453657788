














import Workers from "@/api/Workers"
import { useUser } from "@/store/user"

export default defineComponent({
  emits: ["input"],
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const user = useUser()

    const loading = ref(true)
    const queues = ref([] as string[])

    async function fetchQueues() {
      const response = await Workers.queues(user.authConfig)

      if (response.data !== null) {
        queues.value = response.data
      }
      loading.value = false
    }

    const warningMessage = computed(() =>
      queues.value.includes(props.value)
        ? ""
        : "No worker is currently working on that queue, are you sure it's the right one?"
    )

    onMounted(fetchQueues)

    return { queues, warningMessage }
  },
})
