import type { AxiosRequestConfig } from "axios"

import type { UserSchema } from "./Auth"
import type { Pagination } from "./Base"
import Base from "./Base"

export type ShareLevel = "tenant" | "customer" | "user"

export interface ExecUser {
  id: string
  refreshToken?: string
}

export interface PipelineShortResponse {
  id: string
  shareLevel: ShareLevel
  customerId: string
  tenantId: string
  userId: string
  name: string
  enabled: boolean
  interval: number | null
  workerQueue: string
  user: ExecUser | null
}

export interface PipelineInput {
  deviceId: string
  deviceName: string
  type: "telemetry" | "attribute" | null
  key: string
  example: any
  amount: number
  startTs: number | null
  endTs: number | null
}

export interface PipelineLongResponse extends PipelineShortResponse {
  sourceCode: string
  inputs: PipelineInput[]
  deviceAccessToken: string
}

export interface TimeserieResponse {
  timestamp: string
  inputs: PipelineInput[]
  outputs?: Record<string, any>
  status: 0 | 1
  stdout: string
  details?: string
}

type PipelinesPagination = Pagination<PipelineShortResponse>

export interface PipelineCreate {
  shareLevel: ShareLevel
  name: string
  sourceCode: string
  enabled: boolean
  deviceAccessToken: string | null
  interval: number | null
  workerQueue: string
  inputs: PipelineInput[]
}

export default class Pipelines extends Base {
  public static readonly router: string = "/pipelines"

  public static async getPage(auth: AxiosRequestConfig, page: number, pageSize: number, shareLevel: ShareLevel) {
    const config = {
      ...auth,
      params: {
        page,
        page_size: pageSize,
        share_level: shareLevel,
      },
    }

    const response = await Pipelines._get("", config)

    return Pipelines._handleResponse<PipelinesPagination>(response)
  }

  public static async create(auth: AxiosRequestConfig, data: PipelineCreate) {
    const response = await Pipelines._post("", data, auth)

    return Pipelines._handleResponse<PipelineLongResponse>(response)
  }

  public static async get(auth: AxiosRequestConfig, pipelineId: string) {
    const response = await Pipelines._get(`/${pipelineId}`, auth)

    return Pipelines._handleResponse<PipelineLongResponse>(response)
  }

  public static async putOwner(auth: AxiosRequestConfig, pipelineId: string, newOwner: UserSchema) {
    const url = `/${pipelineId}/owner`
    const response = await Pipelines._put(url, newOwner, auth)

    return Pipelines._handleResponse<PipelineShortResponse>(response)
  }

  public static async put(auth: AxiosRequestConfig, pipelineId: string, data: PipelineCreate) {
    const response = await Pipelines._put(`/${pipelineId}`, data, auth)

    return Pipelines._handleResponse<PipelineLongResponse>(response)
  }

  public static async delete(auth: AxiosRequestConfig, pipelineId: string) {
    const response = await Pipelines._delete(`/${pipelineId}`, auth)

    return Pipelines._handleResponse<string>(response)
  }

  public static async execute(auth: AxiosRequestConfig, pipelineId: string) {
    const url = `/${pipelineId}/execute`
    const response = await Pipelines._post(url, null, auth)

    return Pipelines._handleResponse<TimeserieResponse>(response)
  }

  public static async getTimeseries(
    auth: AxiosRequestConfig,
    pipelineId: string,
    startTs: number | null,
    endTs: number | null
  ) {
    const url = `/${pipelineId}/timeseries?start_ts=${startTs}&end_ts=${endTs}`
    const response = await Pipelines._get(url, auth)

    return Pipelines._handleResponse<TimeserieResponse[]>(response)
  }
}
