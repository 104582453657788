





















































import { mdiPlus, mdiTrashCan } from "@mdi/js"
import { defineComponent } from "@vue/composition-api"

import type { Device, DeviceData, DeviceKeys } from "@/api/Devices"
import Devices from "@/api/Devices"
import type { PipelineInput } from "@/api/Pipelines"
import { useUser } from "@/store/user"

import type { DevicesCache } from "./DeviceSelect.vue"
import DeviceSelect from "./DeviceSelect.vue"
import FetchInterval from "./FetchInterval.vue"

export default defineComponent({
  components: { DeviceSelect, FetchInterval },
  emits: ["input"],
  props: {
    value: {
      required: true,
      type: Array as () => PipelineInput[],
    },
  },
  setup(props, { emit }) {
    const user = useUser()

    const devicesCache = ref({} as DevicesCache)

    function addEmptyInput() {
      const input: PipelineInput = {
        amount: 100,
        deviceId: "",
        deviceName: "",
        endTs: null,
        example: null,
        key: "",
        startTs: null,
        type: null,
      }

      emit("input", [...props.value, input])
    }

    function updateInput(i: number, input: PipelineInput) {
      const copy = [...props.value]
      copy[i] = input

      emit("input", copy)
    }

    function updateType(i: number, value: "telemetry" | "attribute") {
      const copy = {
        ...props.value[i],
        example: null,
        key: "",
        type: value,
      }

      updateInput(i, copy)
    }

    function updateDevice(i: number, device: Device) {
      const copy: PipelineInput = {
        amount: 100,
        deviceId: device.id,
        deviceName: device.name,
        endTs: null,
        example: null,
        key: "",
        startTs: null,
        type: null,
      }

      updateInput(i, copy)
    }

    function updateKey(i: number, key: string) {
      const { deviceId, type } = props.value[i]
      if (!type) return

      const copy: PipelineInput = {
        ...props.value[i],
        example: devicesCache.value[deviceId].data[type][key],
        key,
      }

      updateInput(i, copy)
    }

    function updateFetch(i: number, fetchInterval: any) {
      const copy: PipelineInput = {
        ...props.value[i],
        amount: fetchInterval["limit"],
        endTs: fetchInterval["end"],
        startTs: fetchInterval["start"],
      }

      updateInput(i, copy)
    }

    function deleteInput(i: number) {
      const copy = [...props.value]
      copy.splice(i, 1)

      emit("input", copy)
    }

    const typeSelect = [
      {
        text: "Telemetry",
        value: "telemetry",
      },
      {
        text: "Attribute",
        value: "attribute",
      },
    ]

    function addToCache(deviceId: string, keys: DeviceKeys, data: DeviceData) {
      const copy = { ...devicesCache.value }
      const deviceCache = { data, keys }
      copy[deviceId] = deviceCache
      devicesCache.value = copy
    }

    async function fetchDeviceData(deviceId: string) {
      if (!deviceId) return
      if (deviceId in devicesCache) return

      const keys = await Devices.keys(user.authConfig, deviceId)
      const data = await Devices.latest(user.authConfig, deviceId)

      if (keys.data && data.data) {
        addToCache(deviceId, keys.data, data.data)
      }
    }

    onMounted(async () => {
      for (let i = 0; i < props.value.length; i++) {
        await fetchDeviceData(props.value[i].deviceId)
        updateKey(i, props.value[i].key)
      }
    })

    const icons = { mdiPlus, mdiTrashCan }

    return {
      addEmptyInput,
      deleteInput,
      devicesCache,
      icons,
      typeSelect,
      updateDevice,
      updateFetch,
      updateKey,
      updateType,
    }
  },
})
