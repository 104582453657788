import type { Ref } from "@vue/composition-api"

import type { PipelineInput } from "@/api/Pipelines"

interface SignatureForm {
  name?: string
  sourceCode: string
}

export function useSlugify() {
  function slugify(str: string) {
    return str.toLowerCase().replaceAll(" ", "_")
  }

  function slugifyInput(input: PipelineInput) {
    return slugify(`${input.deviceName}__${input.key}`)
  }

  return {
    slugify,
    slugifyInput,
  }
}

export function useSignature(validInputs: Ref<PipelineInput[]>, form: SignatureForm) {
  const { slugify, slugifyInput } = useSlugify()

  const inputsList = computed(() => validInputs.value.map((i) => slugifyInput(i)))

  const inputsExample = computed(() =>
    JSON.stringify(Object.fromEntries(validInputs.value.map((input) => [slugifyInput(input), input.example])), null, 2)
  )

  const functionSignature = computed(
    () => `def ${slugify(form.name ?? "")}(${inputsList.value.length ? "tb, " : "tb"}${inputsList.value.join(", ")}):\n`
  )

  watch(functionSignature, () => (form.sourceCode = form.sourceCode.replace(/^.*\n?/, functionSignature.value)))

  return {
    functionSignature,
    inputsExample,
  }
}
