import type { AxiosRequestConfig } from "axios"

import type { Pagination } from "./Base"
import Base from "./Base"

export interface Device {
  id: string
  name: string
}

export interface DeviceKeys {
  attribute: string[]
  telemetry: string[]
}

interface TelemetryElem {
  ts: number
  value: any
}

export interface DeviceData {
  attribute: Record<string, any>
  telemetry: Record<string, TelemetryElem[]>
}

type DevicePagination = Pagination<Device>

export default class Devices extends Base {
  public static readonly router: string = "/devices"

  public static async getPage(auth: AxiosRequestConfig, page: number, pageSize: number, textSearch: string) {
    const config: AxiosRequestConfig = {
      ...auth,
      params: {
        page,
        page_size: pageSize,
        text_search: textSearch,
      },
    }

    const response = await Devices._get("", config)

    return Devices._handleResponse<DevicePagination>(response)
  }

  public static async keys(auth: AxiosRequestConfig, deviceId: string) {
    const response = await Devices._get(`/${deviceId}/keys`, auth)

    return Devices._handleResponse<DeviceKeys>(response)
  }

  public static async latest(auth: AxiosRequestConfig, deviceId: string) {
    const response = await Devices._get(`/${deviceId}/latest?limit=2`, auth)

    return Devices._handleResponse<DeviceData>(response)
  }

  public static async credentials(auth: AxiosRequestConfig, deviceId: string) {
    const response = await Devices._get(`/${deviceId}/credentials`, auth)

    return Devices._handleResponse<string>(response)
  }
}
