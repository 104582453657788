




















































































import { mdiDevices } from "@mdi/js"

import type { PipelineInput, PipelineLongResponse } from "@/api/Pipelines"
import Pipelines from "@/api/Pipelines"
import * as rules from "@/composables/formRules"
import { useInterval } from "@/composables/interval"
import { useSignature } from "@/composables/signature"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

import Alert from "./Alert.vue"
import DeviceTokenSelect from "./DeviceTokenSelect.vue"
import PipelineInputs from "./PipelineInputs.vue"
import PythonEditor from "./PythonEditor.vue"
import WorkerSelect from "./WorkerSelect.vue"

interface IVForm {
  validate: () => boolean
  reset: () => void
}

export default defineComponent({
  components: { Alert, DeviceTokenSelect, PipelineInputs, PythonEditor, WorkerSelect },
  props: {
    prefill: Object as () => PipelineLongResponse,
  },
  setup(props, { root }) {
    const user = useUser()
    const { statusAlert, setStatus, resetStatus } = useStatus()

    const valid = ref(false)
    const edit = !!props.prefill
    const formRef = ref<IVForm | null>(null)
    const inputs = ref(props.prefill?.inputs ?? ([] as PipelineInput[]))
    const deviceTokenModal = ref(false)

    function isValid(input: PipelineInput) {
      return input.deviceId && input.type && input.key
    }

    const isOwner = computed(() => {
      if (!props.prefill) return true

      return user.isAdmin || user.user?.id == props.prefill.userId
    })

    const validInputs = computed(() => inputs.value.filter(isValid))

    const execUser = computed(() => (manual.value ? null : user.execUser))

    const { disabled: manual, intervalFields, intervalSelect, interval } = useInterval(props.prefill?.interval)

    const form = reactive({
      deviceAccessToken: props.prefill?.deviceAccessToken ?? null,
      enabled: props.prefill?.enabled ?? true,
      id: props.prefill?.id ?? null,
      name: props.prefill?.name ?? "",
      shareLevel: props.prefill?.shareLevel ?? "customer",
      sourceCode: props.prefill?.sourceCode ?? "",
      workerQueue: props.prefill?.workerQueue ?? "celery",
    })

    const shareSelect = [
      {
        text: "Only the user",
        value: "user",
      },
      {
        text: "Same customer",
        value: "customer",
      },
      {
        text: "Same tenancy",
        value: "tenant",
      },
    ]

    const { functionSignature, inputsExample } = useSignature(validInputs, form)

    async function upsertPipeline() {
      resetStatus()
      const data = {
        ...form,
        inputs: validInputs.value,
        interval: interval.value,
        user: execUser.value,
      }

      let response
      if (edit) {
        response = await Pipelines.put(user.authConfig, props.prefill.id, data)
      } else {
        response = await Pipelines.create(user.authConfig, data)
      }

      if (response.data != null) {
        root.$router.push(`/pipelines/${response.data.id}`)
      } else {
        setStatus("error", response.error)
      }
    }

    function onSubmit() {
      formRef.value?.validate()

      if (valid.value) {
        upsertPipeline()
      }
    }

    return {
      deviceTokenModal,
      edit,
      form,
      formRef,
      functionSignature,
      inputs,
      inputsExample,
      intervalFields,
      intervalSelect,
      isOwner,
      manual,
      mdiDevices,
      onSubmit,
      resetStatus,
      rules,
      shareSelect,
      statusAlert,
      valid,
    }
  },
})
