export function useInterval(initInterval: number | null = 0) {
  const disabled = ref(initInterval === null)

  const intervalFields = reactive({
    multiplier: 1,
    number: initInterval ?? 0,
  })

  const interval = computed(() => (disabled.value ? null : intervalFields.number * intervalFields.multiplier))

  const intervalSelect = [
    {
      text: "seconds",
      value: 1,
    },
    {
      text: "minutes",
      value: 60,
    },
    {
      text: "hours",
      value: 60 * 60,
    },
    {
      text: "days",
      value: 60 * 60 * 24,
    },
  ]

  return {
    disabled,
    interval: readonly(interval),
    intervalFields,
    intervalSelect,
  }
}
