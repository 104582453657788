import type { AxiosRequestConfig } from "axios"

import Base from "./Base"

export interface WorkerStatus {
  name: string
  uptime: number
  processes: number
  maxProcesses: number
  total: number
  queues: string[]
  active: number
}

export interface SchedulerStatus {
  websockets: number
  pipelines: number
  workload: number
  activeQueues: string[]
  inactiveQueues: string[]
  revoked: number
}

export default class Workers extends Base {
  public static readonly router: string = "/workers"

  public static async get(auth: AxiosRequestConfig) {
    const response = await Workers._get("", auth)

    return Workers._handleResponse<WorkerStatus[]>(response)
  }

  public static async queues(auth: AxiosRequestConfig) {
    const response = await Workers._get("/queues", auth)

    return Workers._handleResponse<string[]>(response)
  }

  public static async status(auth: AxiosRequestConfig) {
    const response = await Workers._get("/status", auth)

    return Workers._handleResponse<SchedulerStatus>(response)
  }
}
