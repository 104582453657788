








































import { mdiMagnify } from "@mdi/js"
import { debounce } from "ts-debounce"
import type { StateChanger } from "vue-infinite-loading"
import type InfiniteLoading from "vue-infinite-loading"

import type { Device, DeviceData, DeviceKeys } from "@/api/Devices"
import Devices from "@/api/Devices"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

import Alert from "./Alert.vue"

interface DeviceCache {
  keys: DeviceKeys
  data: DeviceData
}

export type DevicesCache = Record<string, DeviceCache>

export default defineComponent({
  components: { Alert },
  emits: ["update:token", "close"],
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },
  },
  setup(_, { emit }) {
    const user = useUser()
    const { statusAlert, setStatus, resetStatus } = useStatus()

    const page = ref(0)
    const pageSize = 25
    const loader = ref<InfiniteLoading | null>(null)

    const devices = ref([] as Device[])
    const search = ref("")
    const selected = ref("")

    async function fetchDevices() {
      resetStatus()
      const response = await Devices.getPage(user.authConfig, page.value, pageSize, search.value)
      if (response.data != null) {
        devices.value.push(...response.data.data)
        if (!devices.value.find((d) => d.id == selected.value)) {
          selected.value = ""
        }

        return response.data.data.length != 0
      } else {
        setStatus("error", response.error)
        return false
      }
    }

    function close() {
      selected.value = ""
      search.value = ""
      emit("close")
    }

    async function fetchCredentials() {
      const response = await Devices.credentials(user.authConfig, selected.value)

      if (response.data != null) {
        emit("update:token", response.data)
        close()
      } else {
        setStatus("error", response.error)
        selected.value = ""
      }
    }

    async function infiniteLoader($state: StateChanger) {
      const hasMore = await fetchDevices()
      if (hasMore) {
        page.value += 1
        $state.loaded()
      } else {
        $state.complete()
      }
    }

    const debouncedfetchDevices = debounce(async () => {
      page.value = 0
      devices.value = []
      loader.value?.stateChanger.reset()
    }, 500)
    watch(search, () => debouncedfetchDevices())

    return {
      close,
      devices,
      fetchCredentials,
      infiniteLoader,
      loader,
      mdiMagnify,
      resetStatus,
      search,
      selected,
      statusAlert,
    }
  },
})
