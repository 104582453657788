













import { computed, defineComponent } from "@vue/composition-api"

export default defineComponent({
  components: {
    VEditor: require("vue2-ace-editor"),
  },
  emits: ["input"],
  props: {
    minLines: {
      type: Number,
    },
    readonly: {
      type: Boolean,
    },
    value: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    function editorInit() {
      require("brace/ext/language_tools") //language extension prerequsite...
      require("brace/mode/python")
      require("brace/theme/chrome")
    }

    const valueLength = computed(() => props.value.split("\n").length)

    return { editorInit, valueLength }
  },
})
