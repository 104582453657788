







































import { mdiMagnify } from "@mdi/js"
import { debounce } from "ts-debounce"
import type { StateChanger } from "vue-infinite-loading"
import type InfiniteLoading from "vue-infinite-loading"

import type { Device, DeviceData, DeviceKeys } from "@/api/Devices"
import Devices from "@/api/Devices"
import type { PipelineInput } from "@/api/Pipelines"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

import Alert from "./Alert.vue"

interface DeviceCache {
  keys: DeviceKeys
  data: DeviceData
}

export type DevicesCache = Record<string, DeviceCache>

export default defineComponent({
  components: { Alert },
  emits: ["update:cache", "update:device"],
  props: {
    devicesCache: {
      required: true,
      type: Object as () => DevicesCache,
    },
    value: {
      required: true,
      type: Object as () => PipelineInput,
    },
  },
  setup(props, { emit }) {
    const user = useUser()
    const { statusAlert, setStatus, resetStatus } = useStatus()
    const dialog = ref(false)
    const devices = ref([] as Device[])
    const page = ref(0)
    const pageSize = 25
    const search = ref("")
    const selected = ref("")

    const loader = ref<InfiniteLoading | null>(null)

    async function fetchDevices() {
      resetStatus()
      const response = await Devices.getPage(user.authConfig, page.value, pageSize, search.value)

      if (response.data != null) {
        devices.value.push(...response.data.data)
        if (!devices.value.find((d) => d.id == selected.value)) {
          selected.value = ""
        }

        return response.data.data.length != 0
      } else {
        setStatus("error", response.error)
        return false
      }
    }

    async function fetchDeviceData(deviceId: string) {
      if (!deviceId) return
      if (deviceId in props.devicesCache) return

      const keys = await Devices.keys(user.authConfig, deviceId)
      const data = await Devices.latest(user.authConfig, deviceId)

      if (keys.data && data.data) {
        addToCache(deviceId, keys.data, data.data)
      }
    }

    function addToCache(deviceId: string, keys: DeviceKeys, data: DeviceData) {
      const copy = { ...props.devicesCache }
      const deviceCache = { data, keys }
      copy[deviceId] = deviceCache
      emit("update:cache", copy)
    }

    function cancel() {
      selected.value = ""
      search.value = ""
      dialog.value = false
    }

    function confirmSelect() {
      const confirmed = devices.value.find((d) => d.id == selected.value) ?? null
      if (!confirmed) return

      emit("update:device", confirmed)
      dialog.value = false

      fetchDeviceData(confirmed.id)
    }

    async function infiniteLoader($state: StateChanger) {
      const hasMore = await fetchDevices()
      if (hasMore) {
        page.value += 1
        $state.loaded()
      } else {
        $state.complete()
      }
    }

    const debouncedfetchDevices = debounce(async () => {
      page.value = 0
      devices.value = []
      loader.value?.stateChanger.reset()
    }, 600)
    watch(search, () => debouncedfetchDevices())

    return {
      cancel,
      confirmSelect,
      devices,
      dialog,
      infiniteLoader,
      loader,
      mdiMagnify,
      resetStatus,
      search,
      selected,
      statusAlert,
    }
  },
})
